.formContainer {
  width: 100%;
}

.notification {
  margin-top: var(--cui-spacings-mega);
  margin-bottom: var(--cui-spacings-giga);
}

.bottomSpacing {
  margin-bottom: var(--cui-spacings-giga);
}

.passwordInput {
  margin-bottom: var(--cui-spacings-mega);
}

.webauthnNotification {
  margin-bottom: var(--cui-spacings-kilo);
}

.buttonGroup {
  container-name: button-group;
}

.buttonGroup div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.buttonGroup button {
  width: 100%;
}
