.resetButton {
  padding: 2px;
  font: inherit;
  color: var(--cui-fg-subtle);
  pointer-events: all;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: var(--cui-border-radius-byte);
  outline: none;
  transition: fill var(--cui-transitions-default);
}

.resetButton:hover {
  color: var(--cui-fg-subtle-hovered);
}

.resetButton:focus {
  color: var(--cui-fg-subtle-pressed);
}

.visibilityToggleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
}

.strengthMeterContainer {
  margin-bottom: var(--cui-spacings-mega);
}

.coloredProgressBar {
  margin-bottom: var(--cui-spacings-bit);
}

.coloredProgressBar > span::after {
  background-color: var(--progress-bar-color);
}

.coloredBody {
  font-weight: normal;
}

.coloredBodyBold {
  font-weight: bold;
}
