.qrCodeColumn {
  align-self: center;
  max-height: fit-content;
  padding: 56px 32px;
  border: 1px solid #ccc;
  border-radius: 16px;
}

@media (max-width: 767px) {
  .qrCodeColumn {
    display: none;
  }
}

.loginWithQRCodeContainer {
  position: relative;
  display: block;
  align-self: center;
  margin: 0 auto;
}

.qrCanvasContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bodyMarginTop {
  display: block;
  margin-top: var(--cui-spacings-giga);
  text-align: center;
}

.bodyMarginTopSmall {
  margin-top: var(--cui-spacings-byte);
  text-align: center;
}
