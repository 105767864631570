.bodyMarginTop {
  display: block;
  margin-top: var(--cui-spacings-giga);
  text-align: center;
}

.bodyMarginTopSmall {
  margin-top: var(--cui-spacings-byte);
  text-align: center;
}

.headlineMargin {
  width: 100%;
  margin-bottom: var(--cui-spacings-giga);
  text-align: left;
}

.bodyMargin {
  width: 100%;
  margin-bottom: var(--cui-spacings-mega);
  text-align: left;
}

.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
