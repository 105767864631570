.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: var(--cui-spacings-mega);
}

.main {
  flex-grow: 1;
}

.header {
  padding: 0 var(--cui-spacings-mega);
}

.closeButton {
  margin-top: var(--cui-spacings-kilo);
}

.content {
  display: flex;
  flex-direction: column;
  align-content: justify-between;
  min-height: 100vh;
}

.wrapper {
  width: 100%;
  min-height: calc(100vh - 202px);
}

.wrapperExtraColumn {
  display: flex;
  align-items: center;
  justify-self: stretch;
  width: 40%;
  min-height: calc(100vh - 202px);
}

@media (max-width: 768px) {
  .wrapperExtraColumn {
    width: 100%;
    min-height: auto;
    margin-bottom: var(--cui-spacings-giga);
  }
}

.iframeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.iframe {
  width: 328px;
  height: 368px;
  margin-bottom: var(--cui-spacings-giga);
  background-color: pink;
  border-radius: var(--cui-border-radius-mega);
}

@media (min-width: 960px) {
  .iframe {
    width: 384px;
    height: 338px;
    margin: var(--cui-spacings-mega);
  }
}

.languageSelect {
  margin-right: var(--cui-spacings-mega);
}

.notification {
  margin-bottom: var(--cui-spacings-giga);
}

.box:not(:last-of-type) {
  margin-bottom: var(--cui-spacings-giga);
}

@media (min-width: 480px) {
  .box:not(:last-of-type) {
    margin-bottom: var(--cui-spacings-peta);
  }
}

.authMain {
  display: flex;
  align-items: stretch;
  justify-items: stretch;
  width: 100%;
  max-width: 600px;
  padding: 0 var(--cui-spacings-mega);
  margin: 0 auto;
}

.authMainExtraColumn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 0 var(--cui-spacings-mega);
  margin: 0 auto;
}

@media (max-width: 768px) {
  .authMainExtraColumn {
    flex-direction: column;
  }
}
